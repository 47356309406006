import NavBarOtherPages from "../../components/NavBarOtherPages";
import Footer from "../Home/components/Footer";
import Header from "../Home/components/Header";
import Explains from "./components/Explains";

export default function Explain() {
    return (
        <div>
            <NavBarOtherPages />
            <Explains />
            <Footer />
        </div>
    )
}