import VivienPicture from "../../../assets/img/Vivien/thumb-concept-34.png";
import ClementPicture from "../../../assets/img/Vivien/thumb-concept-3.png"

export default function Vivien() {
    return (
        <div id="vivien" className="w-full min-h-screen flex items-center justify-center bg-[#F6F7FB]">
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12">
                <div className="flex flex-col md:flex-row flex-between">
                    <div className="w-full md:w-1/2 grow flex items-center justify-center">
                        <img src={VivienPicture} alt="vivien" />
                    </div>
                    <div className="w-full mt-12 md:mt-0 md:w-1/2 grow flex items-center">
                        <div className=" grow flex flex-col gap-8">
                            <div className="w-1/3 h-2 bg-[#FF0101]"></div>
                            <div className="text-4xl font-bold text-[#252B42] leading-[50px] tracking-[0.2px]">“ Une sacrée histoire ! ”</div>
                            <div className="text-sm leading-[20px] tracking-[0.2px] text-[#737373]">
                                Prothésiste dentaire de formation, Vivien Sixdenier a créé LIO en mars 2020 à Villeurbanne peu après sa sortie d’étude. Membre et conférencier du consensus scientifique de l’ITI, il diffuse sa vision digitale dans le secteur dentaire ainsi que les nouvelles techniques développées en interne, notamment chirurgicale. Il est également formateur concernant les réhabilitations buccales complexes et implantaires. Depuis son plus jeune âge, il a été captivé par l'histoire et l'a mise en relation avec sa passion pour l'entrepreneuriat.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row flex-between">
                    <div className="w-full mt-12 md:mt-0 md:w-1/2 grow flex items-center">
                        <div className=" grow flex flex-col gap-8">
                            <div className="w-1/3 h-2 bg-[#FF0101]"></div>
                            <div className="text-4xl font-bold text-[#252B42] leading-[50px] tracking-[0.2px]">“ En selle Marcel ! ”</div>
                            <div className="text-sm leading-[20px] tracking-[0.2px] text-[#737373]">
                                Également prothésiste dentaire de formation, Clément Lafiandra a rejoint l’aventure LIO en septembre 2021. Fort d’une grande expérience en laboratoire de prothèse conventionnelle, il apporte sa vision et son savoir-faire. Il est directeur du laboratoire LIO Modélisation. Son énergie débordante est certainement liée à sa passion pour le cyclisme…
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 grow flex items-center justify-center">
                        <img src={ClementPicture} alt="Clément" />
                    </div>
                </div>
            </div>
        </div>
    );
}
