import Seo from "../../../assets/img/Header/final_lio_header.mp4"

export default function Header() {
    return (
        <div id="header" className="relative w-full min-h-full flex items-center justify-center bg-[#73737311]">
            <video
                autoPlay
                muted={true}
                className="h-full w-full md:static mt-12"
            >
                <source src={Seo} type="video/mp4" />
                Votre navigateur ne prend pas en charge la balise video.
            </video>
            <div className="absolute top-1/2 -translate-y-1/2 left-0 px-4 md:px-0 md:left-12 flex flex-col gap-0 lg:gap-8">
                <div className="h-6 overflow-hidden relative">
                    <div className="absolute left-0 top-0 uppercase text-base font-bold leading-[24px] tracking-[0.1px] text-[#010B70]" id="titleHeader">Lio Lft</div>
                </div>
                <div className="text-lg sm:text-3xl lg:text-6xl text-[#252B42] lg:leading-[80px] tracking-[0.2px] w-full md:w-[60%] font-bold" id="title">
                    Le Laboratoire Nouvelle Génération
                </div>
                <div id="subtitle" className="text-sm sm:text-lg xl:text-xl text-[#737373] sm:leading-[30px] tracking-[0.2px] w-[60%] md:w-[60%]">
                    Bienvenue dans le laboratoire nouvelle génération <b>100% numérique</b> et <b>100% français</b>
                </div>
                <div className="flex flex-row gap-2.5">
                    <a
                        href="https://laboratoire.lio-lft.fr/register"
                        className="button py-2 px-4 md:py-4 md:px-10 text-sm font-bold leading-[22px] tracking-[0.2px] rounded-[5px] bg-[#010B70] text-white">
                        Rejoindre Lio
                    </a>
                    <a
                        href="https://laboratoire.lio-lft.fr/"
                        className="button py-2 px-4 md:py-4 md:px-10 border border-[#010B70] text-sm font-bold leading-[22px] tracking-[0.2px] rounded-[5px] bg-white text-[#010B70]"
                    >
                        En savoir plus
                    </a>
                </div>
            </div>
        </div>
    );
}
