import Title from "../../../components/Title";

import Expertise1 from "../../../assets/img/Expertise/expertise1.png";
import Expertise2 from "../../../assets/img/Expertise/screen_lio.png"
import Expertise3 from "../../../assets/img/Expertise/expertise3.png";

const datas = [
    {
        id: 100,
        title: "La puissance du numérique",
        p1: "Le monde dentaire est en pleine mutation technologique. Nous entrons dans une nouvelle ère, celle de la dentisterie numérique. La mission de Lio est d'accompagner les cabinets dentaires et les laboratoires dans l'intégration des nouvelles technologies numériques, de proposer une offre sur-mesure et personnalisée afin de vous accompagner dans la modélisation, la production et la formation. Nous avons à cœur de vous proposer des produits entièrement modélisés et fabriqués en France, tout en respectant notre impact sur la planète. Nous nous engageons à être totalement transparent sur notre développement durable et les actions que nous mettons en place.",
        p2: "Vous satisfaire est notre mission principale, alors n'hésitez pas à nous contacter !",
        picture: Expertise1,
    },
    {
        id: 101,
        title: "Une relation privilégiée",
        p1: "Notre interface intelligente développée par notre équipe permet d’échanger efficacement durant toute la durée de traitement de vos patients et facilite grandement votre gestion administrative",
        p2: "",
        picture: Expertise2,
    },
    {
        id: 102,
        title: "Savoir faire",
        p1: "Nous sommes un laboratoire réalisant tous types de prothèses, de la modélisation à la finition, avec des compétences spécifiques concernant les réhabilitations globales prenant en compte la posturologie et les traitements chirurgicaux complexes",
        p2: "",
        picture: Expertise3,
    },
];

export default function Expertise() {
    return (
        <div id='expertise' className="w-full min-h-screen flex items-center justify-center bg-[#FFF]">
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12">
                <Title
                    title="Prothèses Dentaires"
                    subtitle="Notre expertise"
                    description=""
                />
                <div className="flex justify-around flex-col gap-12">
                    {datas.map((data, index) => (
                        <div key={data.id} className="w-full flex flex-col md:flex-row justify-between">
                            {index % 2 !== 0 ? (
                                <>
                                    <div className="w-full md:hidden">
                                        <img src={data.picture} alt="expertise" className="w-full max-h-[40vh] object-cover" />
                                    </div>
                                    <div className="w-full md:w-2/3 flex flex-col gap-8 md:pr-8 mt-8 md:mt-0">
                                        <span className="uppercase text-[#010B70] font-bold text-2xl leading-[32px] tracking-[0.1px]">{data.title}</span>
                                        <div className="flex flex-col gap-4">
                                            <span className="text-sm leading-[20px] tracking-[0.2px]">{data.p1}</span>
                                            {data.p2 && <span className="text-sm leading-[20px] tracking-[0.2px]">{data.p2}</span>}
                                        </div>
                                    </div>
                                    <div className="hidden md:block md:w-1/2">
                                        <img src={data.picture} alt="expertise" className="w-full" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="w-full md:w-1/3">
                                        <img src={data.picture} alt="expertise" className={`w-full max-h-[40vh] md:max-h-auto object-cover ${index === 0 && "aspect-square"}`} />
                                    </div>
                                    <div className="w-full md:w-2/3 flex flex-col gap-8 md:pl-8 mt-8 md:mt-0">
                                        <span className="uppercase text-[#FF0101] font-bold text-2xl leading-[32px] tracking-[0.1px]">{data.title}</span>
                                        <div className="flex flex-col gap-4">
                                            <span className="text-sm leading-[20px] tracking-[0.2px]">{data.p1}</span>
                                            {data.p2 && <span className="text-sm leading-[20px] tracking-[0.2px]">{data.p2}</span>}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
