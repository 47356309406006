import France from "../../../assets/img/National/france.png";
import Cible from "../../../assets/img/National/cible.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Title from "../../../components/Title";

export default function Labo() {
    const [cibles, setCibles] = useState<{ lyon: boolean; amiens: boolean; nice: boolean }>({
        lyon: true,
        amiens: false,
        nice: false,
    });

    return (
        <div id="labos" className="w-full min-h-screen flex items-center justify-center bg-[#F6F7FB]">
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-12">
                <Title
                    title="Le Laboratoire"
                    subtitle="Nos laboratoires en France"
                    description="Avec le succès de notre laboratoire lyonnais, des succursales ont vu le jour afin d’apporter un service de proximité et de répondre efficacement aux besoins de nos praticiens dans d’autres régions"
                />
                <div className="flex flex-col gap-12 md:gap-0 lg:flex-row justify-between">
                    <div className="w-full lg:w-1/2 h-fit flex items-center grow justify-center">
                        <div className="flex items-center justify-start relative lg:pr-8">
                            <img src={France} alt="map france" className="w-full aspect-square" />
                            <img
                                src={Cible}
                                alt="cible lyon"
                                className={`origin-center absolute bottom-[43%] right-[35%] lg:right-[38%] aspect-square transition-all ${
                                    cibles.lyon ? "w-[10%] opacity-100" : "w-[8%] opacity-50"
                                }`}
                            />
                            <img
                                src={Cible}
                                alt="cible amiens"
                                className={`origin-center absolute top-[10%] right-[45%] lg:right-[48%] aspect-square transition-all ${
                                    cibles.amiens ? "w-[10%] opacity-100" : "w-[8%] opacity-50"
                                }`}
                            />
                            <img
                                src={Cible}
                                alt="cible nice"
                                className={`origin-center absolute bottom-[25%] right-[32%] lg:right-[37%] aspect-square transition-all ${
                                    cibles.nice ? "w-[10%] opacity-100" : "w-[8%] opacity-50"
                                }`}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex flex-col grow gap-4 lg:pl-8 md:pt-8 lg:pt-0">
                        <div className="bg-[#929EC34D] px-[25px] py-3 2xl:px-[50px] 2xl:py-5 rounded-2xl">
                            <button
                                onClick={() => setCibles({ nice: false, lyon: false, amiens: !cibles.amiens })}
                                className="flex justify-between items-center w-full"
                            >
                                <span className="text-2xl 2xl:text-3xl text-[#001866] font-bold leagin-[50px] tracking-[0.2px]">Lio Amiens</span>
                                <FontAwesomeIcon icon={cibles.amiens ? "chevron-up" : "chevron-down"} className="text-[#001866] h-4 2xl:h-6 w-4 2xl:w-6" />
                            </button>
                            <div className={`flex flex-col overflow-hidden transition-all duration-700 ${cibles.amiens ? "h-52 pt-4" : "h-0"}`}>
                                <span className="text-[#001866] font-normal leading-[20px] tracking-[0.2px] text-sm mb-2">
                                Laboratoire récent, au service des dentistes des Hauts-de-France.
                                </span>
                                <span className="text-[#001866] font-semibold text-sm mb-1">Horaires :</span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Lun. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mar. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mer. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Jeu. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Ven. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                            </div>
                        </div>
                        <div className="bg-[#929EC34D] px-[25px] py-3 2xl:px-[50px] 2xl:py-5 rounded-2xl">
                            <button
                                onClick={() => setCibles({ amiens: false, nice: false, lyon: !cibles.lyon })}
                                className="flex justify-between items-center w-full"
                            >
                                <span className="text-2xl 2xl:text-3xl text-[#001866] font-bold leagin-[50px] tracking-[0.2px]">Lio Lyon</span>
                                <FontAwesomeIcon icon={cibles.lyon ? "chevron-up" : "chevron-down"} className="text-[#001866] h-4 2xl:h-6 w-4 2xl:w-6" />
                            </button>
                            <div className={`flex flex-col overflow-hidden transition-all duration-700 ${cibles.lyon ? "h-52 pt-4" : "h-0"}`}>
                                <span className="text-[#001866] font-normal leading-[20px] tracking-[0.2px] text-sm mb-2">
                                    Historiquement le premier laboratoire LIO de France, LIO Lyon dessert les dentistes du pays Lyonnais.
                                </span>
                                <span className="text-[#001866] font-semibold text-sm mb-1">Horaires :</span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Lun. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mar. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mer. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Jeu. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Ven. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                            </div>
                        </div>
                        <div className="bg-[#929EC34D] px-[25px] py-3 2xl:px-[50px] 2xl:py-5 rounded-2xl">
                            <button
                                onClick={() => setCibles({ lyon: false, amiens: false, nice: !cibles.nice })}
                                className="flex justify-between items-center w-full"
                            >
                                <span className="text-2xl 2xl:text-3xl text-[#001866] font-bold leagin-[50px] tracking-[0.2px]">Lio Nice</span>
                                <FontAwesomeIcon icon={cibles.nice ? "chevron-up" : "chevron-down"} className="text-[#001866] h-4 2xl:h-6 w-4 2xl:w-6" />
                            </button>
                            <div className={`flex flex-col overflow-hidden transition-all duration-700 ${cibles.nice ? "h-52 pt-4" : "h-0"}`}>
                                <span className="text-[#001866] font-normal leading-[20px] tracking-[0.2px] text-sm mb-2">
                                    Implanté au cœur de la Côte d'Azur, notre laboratoire répond aux besoins des dentistes de la région.
                                </span>
                                <span className="text-[#001866] font-semibold text-sm mb-1">Horaires :</span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Lun. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mar. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Mer. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Jeu. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                                <span className="text-[#001866] text-sm">
                                    <span className="font-semibold">Ven. :</span> 8h30 - 12h00 | 13h00 - 17h30
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
