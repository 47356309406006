export default function Page404() {
    return (
        <div className="h-screen w-screen flex flex-col items-center justify-center gap-4">
            <div className="text-center text-9xl font-bold text-gray-200">
                404
            </div>
            <div className="text-center text-4xl font-bold text-royal-900">
                Oh oh !
            </div>
            <div className="text-center text-base text-gray-500">
                La page que vous cherchez n'existe pas
            </div>
            <a href="/" className="flex items-center justify-center rounded-xl px-4 py-2 bg-royal-900 text-white">
                Retour à l'accueil
            </a>
        </div>
    )
}