import NavBar from "../../components/NavBar";
import Credits from "./components/Credits";
import Expertise from "./components/Expertise";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Join from "./components/Join";
import Labo from "./components/Labo";
// import Opinion from "./components/Opinion";
import Team from "./components/Team";
import Vivien from "./components/Vivien";

function App() {
    return (
        <div>
            <NavBar />
            <Header />
            <Expertise />
            <Vivien />
            <Team />
            <Labo />
            <Join />
            <Footer />
            <Credits />
        </div>
    );
}

export default App;
