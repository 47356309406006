import BarsConcept from "../../../assets/img/Team/thumb-concept-3.png";
import Title from "../../../components/Title";

const team = [
    {
        id: 1,
        name: "Mathilde",
    },
    {
        id: 4,
        name: "Marina",
    },
    {
        id: 5,
        name: "Sylvain",
    },
    {
        id: 7,
        name: "Charline",
    },
    {
        id: 8,
        name: "Shirley",
    },
    {
        id: 9,
        name: "Pauline",
    },
    {
        id: 10,
        name: "Joanna",
    },
    {
        id: 11,
        name: "Lorine",
    },
    {
        id: 12,
        name: "Carine",
    },
    {
        id: 13,
        name: "Andrea",
    },
    {
        id: 14,
        name: "Christelle",
    },
];

const teamNice = [
    {
        id: 15,
        name: "Marion",
    },
];

const teamAmiens = [
    {
        id: 16,
        name: "Maeva",
    },
];

export default function Team() {
    return (
        <div id="team" className="w-full min-h-screen flex items-center justify-center bg-[#FFF]">
            <div className="px-4 py-12 md:w-full md:max-w-[1280px] flex flex-col justify-center items-left gap-4">
                <Title title="Le Laboratoire" subtitle="Notre équipe motivée et énergique" description="" />
                <h2 className="text-[#252B42] font-bold text-2xl pl-4">Amiens</h2>
                <div className="flex justify-start flex-wrap">
                    {teamAmiens.map((member) => (
                        <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 h-36" key={member.id}>
                            <div className="w-full h-full rounded-[20px] relative overflow-hidden bg-white">
                            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#FFFFFF00] via-[#fff] to-[#0C009459] opacity-50 rounded-[20px]"></div>
                                <img src={BarsConcept} alt="bars concept" className="w-full aspect-square absolute bottom-0 left-0 right-0 opacity-100" />
                                <div className="absolute top-2 left-2 w-[calc(100%-16px)] h-[calc(100%-16px)] bg-white opacity-100 rounded-[20px]"></div>
                                <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full flex flex-col items-center justify-center z-40">
                                    {/* <div className="absolute top-0 left-0 w-full flex flex-col items-center justify-center z-40"> */}
                                    <span className="text-2xl font-bold leading-[32px] tracking-[0.1px] text-[#02119B] text-center">{member.name}</span>
                                    <span className="text-center text-base font-medium leading-[20px] tracking-[0.2px] text-[#02119B] opacity-60" dangerouslySetInnerHTML={{ __html : "prothésiste dentaire<br />numérique"}}></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <h2 className="text-[#252B42] font-bold text-2xl pl-4">Lyon</h2>
                <div className="flex justify-start flex-wrap">
                    {team.map((member) => (
                        // <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 aspect-[2.3/3] md:aspect-[2.5/3] lg:aspect-[2.2/3]" key={member.id}>
                        <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 h-36" key={member.id}>
                            <div className="w-full h-full rounded-[20px] relative overflow-hidden bg-white">
                                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#FFFFFF00] via-[#fff] to-[#0C009459] opacity-50 rounded-[20px]"></div>
                                <img src={BarsConcept} alt="bars concept" className="w-full aspect-square absolute bottom-0 left-0 right-0 opacity-100" />
                                <div className="absolute top-2 left-2 w-[calc(100%-16px)] h-[calc(100%-16px)] bg-white opacity-100 rounded-[20px]"></div>
                                <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full flex flex-col items-center justify-center z-40">
                                    {/* <div className="absolute top-0 left-0 w-full flex flex-col items-center justify-center z-40"> */}
                                    <span className="text-2xl font-bold leading-[32px] tracking-[0.1px] text-[#02119B] text-center">{member.name}</span>
                                    <span className="text-center text-base font-medium leading-[20px] tracking-[0.2px] text-[#02119B] opacity-60" dangerouslySetInnerHTML={{ __html : "prothésiste dentaire<br />numérique"}}></span>
                                </div>
                                {/* <img src={User} alt={member.name} className="mx-auto max-h-40 invert absolute bottom-0 left-0 right-0 bg-for-picture" /> */}
                                {/* <div className="absolute bottom-2 left-0 right-0 bg-white px-2.5 py-1 w-fit rounded-full mx-auto text-[#4200FF] text-base font-semibold">
                                    {member.surname}
                                </div> */}
                            </div>
                        </div>
                    ))}
                </div>
                <h2 className="text-[#252B42] font-bold text-2xl pl-4">Nice</h2>
                <div className="flex justify-start flex-wrap">
                    {teamNice.map((member) => (
                        <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 h-36" key={member.id}>
                            <div className="w-full h-full rounded-[20px] relative overflow-hidden bg-white">
                            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#FFFFFF00] via-[#fff] to-[#0C009459] opacity-50 rounded-[20px]"></div>
                                <img src={BarsConcept} alt="bars concept" className="w-full aspect-square absolute bottom-0 left-0 right-0 opacity-100" />
                                <div className="absolute top-2 left-2 w-[calc(100%-16px)] h-[calc(100%-16px)] bg-white opacity-100 rounded-[20px]"></div>
                                <div className="absolute top-1/2 -translate-y-1/2 left-0 w-full flex flex-col items-center justify-center z-40">
                                    {/* <div className="absolute top-0 left-0 w-full flex flex-col items-center justify-center z-40"> */}
                                    <span className="text-2xl font-bold leading-[32px] tracking-[0.1px] text-[#02119B] text-center">{member.name}</span>
                                    <span className="text-center text-base font-medium leading-[20px] tracking-[0.2px] text-[#02119B] opacity-60" dangerouslySetInnerHTML={{ __html : "prothésiste dentaire<br />numérique"}}></span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
