export default function Title({ title, subtitle, description } : { title: string, subtitle: string, description: string }) {
    return (
        <div className="flex flex-col items-start justify-start gap-4">
            <div className="text-[#010B72]/65 font-bold text-sm leading-[24px] tracking-[0.2px]">{title}</div>
            <div className="text-[#252B42] font-bold text-4xl md:leading-[24px] tracking-[0.2px]">{subtitle}</div>
            <div className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm md:w-2/3">
                {description}
            </div>
        </div>
    )
}