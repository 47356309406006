import Title from "../../../components/Title";

import Expertise1 from "../../../assets/img/Expertise/expertise1.png";
import Expertise3 from "../../../assets/img/Expertise/expertise3.png";

const datas = [
    {
        id: 100,
        title: "La puissance du numérique",
        p1: "Le monde dentaire est en pleine mutation technologique. Nous entrons dans une nouvelle ère, celle de la dentisterie numérique. La mission de Lio est d'accompagner les cabinets dentaires et les laboratoires dans l'intégration des nouvelles technologies numériques, de proposer une offre sur-mesure et personnalisée afin de vous accompagner dans la modélisation, la production et la formation. Nous avons à cœur de vous proposer des produits entièrement modélisés et fabriqués en France, tout en respectant notre impact sur la planète. Nous nous engageons à être totalement transparent sur notre développement durable et les actions que nous mettons en place.",
        p2: "Vous satisfaire est notre mission principale, alors n'hésitez pas à nous contacter !",
        picture: Expertise1,
    },
    {
        id: 101,
        title: "Une relation privilégiée",
        p1: "Notre interface intelligente développée par notre équipe permet d’échanger efficacement durant toute la durée de traitement de vos patients et facilite grandement votre gestion administrative",
        p2: "",
        picture: "to be changed",
    },
    {
        id: 102,
        title: "Savoir faire",
        p1: "Nous sommes un laboratoire réalisant tous types de prothèses, de la modélisation à la finition, avec des compétences spécifiques concernant les réhabilitations globales prenant en compte la posturologie et les traitements chirurgicaux complexes",
        p2: "",
        picture: Expertise3,
    },
];

export default function Explains() {
    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center overflow-hidden h-5/6">
            <div className="relative py-3 mx-auto w-full md:w-1/2 h-5/6">
                <div className="absolute inset-0 bg-gradient-to-r from-capucine-700 to-capucine-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl h-5/6"></div>
                <div className="relative px-2 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-14 mx-autovh-5/6">
                    <h1 className="text-3xl text-royal-900 font-semibold text-center mb-6">Plate-forme Lio</h1>
                    <div className="w-2/3 mx-auto text-justify">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at viverra quam. Maecenas id maximus magna. Fusce ipsum quam, pulvinar at euismod a, imperdiet sit amet neque. Morbi faucibus, dui ac lobortis tempor, ipsum velit scelerisque quam, nec varius purus lacus at arcu. In hac habitasse platea dictumst. Suspendisse volutpat bibendum diam eu eleifend.</div>
                    <div className="w-2/3 mx-auto text-justify mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut at viverra quam. Maecenas id maximus magna. Fusce ipsum quam, pulvinar at euismod a, imperdiet sit amet neque. Morbi faucibus, dui ac lobortis tempor, ipsum velit scelerisque quam, nec varius purus lacus at arcu. In hac habitasse platea dictumst. Suspendisse volutpat bibendum diam eu eleifend.</div>
                    <div className="flex justify-between w-full mx-auto mt-12">
                        <a href="https://laboratoire.lio-lft.fr/register" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-royal-900 shadow-md shadow-royal-900/20 hover:shadow-lg hover:shadow-royal-900/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none text-white" type="button">
                            Inscrivez-vous !
                        </a>
                        <a href="https://laboratoire.lio-lft.fr/" className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-capucine-700 text-white shadow-md shadow-capucine-700/20 hover:shadow-lg hover:shadow-capucine-600/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none">
                            Connectez-vous !
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
