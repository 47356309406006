import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-scroll";

export default function Footer() {
    return (
        <div id="footer" className="md:h-72 py-4 w-full bg-white flex items-center justify-center">
            <div className="px-8 md:px-4 md:w-2/3 md:h-44 flex justify-between flex-wrap md:flex-nowrap gap-4">
                <div className="flex flex-col gap-4">
                    <span className="text-royal-900 font-bold text-base mb-1">Informations</span>
                    <span className="text-[#737373] font-bold text-sm leading-8">
                        <Link smooth={true} className="cursor-pointer" to="expertise">Notre expertise</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="team">Equipe</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="labos">Laboratoires</Link> <br />
                        <Link smooth={true} className="cursor-pointer" to="join">Contactez-nous</Link>
                    </span>
                </div>
                <div className="flex flex-col gap-4 items-end md:items-start">
                    <span className="text-royal-900 font-bold text-base mb-1 text-right md:text-left">Légal</span>
                    <span className="text-[#737373] font-bold text-sm leading-8 text-right md:text-left">
                        <a href="/mentions">Mentions Légales</a>
                    </span>
                </div>
                <div className="flex flex-col gap-4 items-end md:items-start">
                    <span className="text-royal-900 font-bold text-base mb-1 text-right md:text-left">Ressources</span>
                    <span className="text-[#737373] font-bold text-sm leading-8 text-right md:text-left">
                        <a href="https://laboratoire.lio-lft.fr/">Panel client</a> <br />
                    </span>
                </div>
                <div className="flex flex-col w-full md:w-fit items-center md:items-start">
                    <span className="text-royal-900 font-bold text-base mb-7 text-center md:text-left">Nous contacter</span>
                    <span className="text-[#737373] font-bold text-sm">
                        <a href="tel:+33478172780" className="flex items-center justify-left gap-2">
                            <FontAwesomeIcon className="text-royal-900" icon="phone" />
                            04 78 17 27 80
                        </a>{" "}
                        <br />
                        <a target="_blank" href="https://www.google.fr/maps/place/13+Av.+Albert+Einstein,+69100+Villeurbanne/@45.782908,4.8781576,16z/data=!3m1!4b1!4m6!3m5!1s0x47f4c01f23574a43:0x14715fa07472aff2!8m2!3d45.782908!4d4.8807325!16s%2Fg%2F11c2cgl35j?entry=ttu" className="flex items-center justify-left gap-2 -mt-3">
                            <FontAwesomeIcon className="text-royal-900" icon="location-dot" />
                            <span>
                                13 Av. Albert Einstein, <br />
                                69100 Villeurbanne
                            </span>
                        </a>{" "}
                        <br />
                        <a href="mailto:info@lio-lft.fr" className="flex items-center justify-left gap-2 -mt-3">
                            <FontAwesomeIcon className="text-royal-900" icon="envelope" />
                            info@lio-lft.fr
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
}
