import { faFacebook, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Credits() {
    return (
        <div id="credits" className="flex items-center justify-center w-full bg-[#FAFAFA] md:h-16">
            <div className="w-2/3 md:h-6 flex flex-col md:flex-row gap-4 md:gap-0 py-4 md:py-0 justify-between items-center">
                <span className="text-[#737373] text-center md:text-left font-bold text-sm">LIO - La French Tooth</span>
                <div className="flex items-center justify-end gap-4 text-royal-900">
                    <a href="https://www.linkedin.com/company/laboratoirelio/" target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                    <a href="https://www.instagram.com/lio_haut_de_france/?hl=fr" target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://www.facebook.com/laboratoirelio" target="_blank" rel="noreferrer" className="h-6 w-6">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </div>
            </div>
        </div>
    );
}
