import { useState } from "react";
import { toast } from "react-toastify";

import pictureFromLio from "../../../assets/img/pictureFromLio.jpeg";

const privateURL = process.env.REACT_APP_PRIVATE_URL;

export default function Join() {
    const [data, setData] = useState({
        name: "",
        email: "",
        subject: "",
        content: ""
    })

    const handleSubmission = async (e: any) => {
        e.preventDefault();

        try {
            const response = await fetch(`${privateURL}/mailer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const res = await response.json();
            toast.success("Votre message a bien été envoyé !")
            setData({
                name: "",
                email: "",
                subject: "",
                content: ""
            })
        } catch (error) {
            console.error(error);
            toast.error("Une erreur est survenue, veuillez réessayer plus tard.")
        }
    }


    const classNameInput = `w-full
    py-3
    px-4
    rounded-[5px]
    bg-[#fff]
    text-[#010B72]
    text-sm
    font-bold
    leading-[22px]
    tracking-[0.2px]
    border-none
    focus:outline-none
    focus:ring-2
    focus:ring-[#fff]
    focus:ring-offset-2
    focus:ring-offset-[#010B72]
    focus:border-none
    transition-all
    duration-300`;

    return (
        <div id="join" className="py-12 px-8 flex flex-col items-center justify-center bg-[#F6F7FB] gap-2.5">
            <div className="text-sm font-bold leading-6 tracking-[.2px] text-[#010B72]/75">Contact</div>
            <div className="text-[40px] leading-[50px] text-center uppercase font-bold text-[#252B42]">Contactez-nous !</div>
            <div className="text-sm font-normal leading-5 tracking-[.2px] md:w-1/3 text-center text-[#737373]">
                Vous avez besoin d'une information supplémentaire ? N'hésitez pas à nous contacter via notre formulaire.
            </div>

            <div className="mb-6 md:mb-0 md:mt-6 flex flex-col md:flex-row justify-center relative md:w-full md:max-w-[1280px] gap-8 md:gap-0">
                <img
                    src={pictureFromLio}
                    alt="join us"
                    className="w-full md:w-1/3 mt-8 rounded-[5px] relative md:ml-6 z-20 h-fit"
                />
                <form onSubmit={handleSubmission} className=" rounded-2xl relative w-full md:w-1/2 bg-[#929EC34D] text-[#010B72] p-4 md:p-10 text-justify md:-ml-6 z-10 flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <label htmlFor="nomSociete" className="text-sm font-bold leading-6 tracking-[.2px] ">
                            Nom de la société
                        </label>
                        <input
                            className={classNameInput}
                            id="nomSociete"
                            type="text"
                            placeholder="Nom de la société"
                            value={data.name}
                            onChange={(e) => setData({ ...data, name: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="email" className="text-sm font-bold leading-6 tracking-[.2px] ">
                            Adresse mail
                        </label>
                        <input
                            className={classNameInput}
                            id="email"
                            type="email"
                            placeholder="Adresse Mail"
                            value={data.email}
                            onChange={(e) => setData({ ...data, email: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="object" className="text-sm font-bold leading-6 tracking-[.2px] ">
                            Objet
                        </label>
                        <input
                            className={classNameInput}
                            id="object"
                            type="text"
                            placeholder="Objet du message"
                            value={data.subject}
                            onChange={(e) => setData({ ...data, subject: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col gap-1">
                        <label htmlFor="message" className="text-sm font-bold leading-6 tracking-[.2px] ">
                            Message
                        </label>
                        <textarea
                            className={classNameInput}
                            id="message"
                            placeholder="Message"
                            value={data.content}
                            onChange={(e) => setData({ ...data, content: e.target.value })}
                        />
                    </div>
                    <button type="submit" className="py-4 px-10 text-sm font-bold leading-[22px] tracking-[0.2px] rounded-[5px] bg-[#ff0101] hover:bg-[#ff010160] transition-all text-white">
                        Envoyer
                    </button>
                </form>
            </div>
        </div>
    );
}
