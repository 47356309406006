import NavBarOtherPages from "../../components/NavBarOtherPages";

export default function Mentions() {
    return (
        <div className="w-full">
            <NavBarOtherPages />
            <div className="w-full max-w-[1280px] mx-auto px-4 pt-28 pb-12">
                <div className="text-[#252B42] font-bold text-4xl md:leading-[24px] tracking-[0.2px] text-center">Mentions Légales</div>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-8">
                    Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux
                    utilisateurs du site lio-lft.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Edition du site</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Le présent site est accessible à l’URL lio-lft.fr, est édité par la société Lio, SARL au capital de 4 900 euros, inscrite au R.C.S. de
                    Vannes sous le numéro 881 924 286, dont le siège social est situé au : <br />
                    <b>
                        13 Avenue Albert Einstein <br />
                        69100 Villeurbanne
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Hébergement</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Le Site est hébergé par la société OVH SAS, situé : <br />
                    <b>
                        2 Rue Kellermann <br />
                        59100 Roubaix - France
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Directeur de publication</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Les Directeurs de la publication du Site sont :<br />
                    <b>
                        Vivien Sixdenier
                        <br />
                        vivien@lio-lft.fr
                    </b>
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Nous contacter</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Vous pouvez vous nous contacter via le formulaire de contact disponible sur le site internet lio-lft.fr/#contact ou par e-mail à l’adresse
                    suivante : info@lio-lft.fr
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Données personnelles</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée conformément au Règlement Général
                    sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD »).
                </p>
                <h2 className="text-[#252B42] font-bold text-2xl md:leading-[24px] tracking-[0.2px] mt-8">Important</h2>
                <p className="text-[#737373] font-normal leading-[20px] tracking-[0.2px] text-sm mt-4">
                    Les droits de propriété intellectuelle afférents au site lio-lft.fr appartiennent à Lio. Toute utilisation, reproduction, diffusion,
                    commercialisation, modification de toute ou partie du site, sans l’autorisation de Lio est prohibée et pourra entraîner des actions et
                    poursuites judiciaires telles que notamment prévues par le code de la propriété intellectuelle et/ou le code civil.
                </p>
            </div>
        </div>
    );
}
